<template>
	<el-form v-loading="isLoading">
    <el-card class="mb-1">
      <div class="title-container">
        <h3 class="mb-2">Change Password</h3>
      </div>

      <el-alert type="warning">Password must be at least 12 characters long and include at least one of those characters: upper-case, lower-case, digits, symbols.</el-alert>

      <el-input type="password" v-model="currentPassword" placeholder="Current Password" class="mt-2"/>
      <el-input type="password" v-model="newPassword" placeholder="New Password" class="mt-2"/>
      <el-input type="password" v-model="newPasswordConfirm" placeholder="Confirm New Password" class="mt-2"/>

      <el-button type="primary" class="mt-2" @click="changePassword()">Change Password</el-button>
    </el-card>

    <el-card class="mb-1">
      <div class="title-container">
        <h3 class="mb-2">MFA</h3>
      </div>
      Current MFA Method: {{ mfaMethod ? mfaMethod : 'None' }}

      <el-row v-if="mfaMethod != null" class="mt-1">
        <el-button @click="removeMfa()">Remove MFA</el-button>
      </el-row>

      <el-row v-if="mfaMethod == null" class="mt-1">
        <el-button @click="setupEmailMfa()">Setup Email MFA</el-button>
      </el-row>

      <el-row v-if="mfaMethod == null" class="mt-1">
        <el-button @click="setupTotpMfa()">Setup TOTP MFA (Google Authenticator / Microsoft Authenticator)</el-button>
      </el-row>
    </el-card>

    <el-dialog
      title="Remove MFA"
      :visible.sync="isShowRemoveMfaDialog"
      width="50%">
      <p>
        To remove MFA, enter your password and current MFA code.
        <el-button @click="sendMfaEmail()" type="primary" class="float-right">Send Email</el-button>
      </p>
      <el-input type="password" v-model="currentPassword" placeholder="Current Password" class="mt-2"/>
      <el-input v-model="mfaCode" placeholder="MFA Code" class="mt-2"/>
      <el-button type="primary" class="mt-2" @click="removeMfaSubmit()">Remove</el-button>
    </el-dialog>

    <el-dialog
      title="Setup Email MFA"
      :visible.sync="isShowEmailSetupDialog"
      width="25%">
        <p>1. Open {{ email }} to receive the MFA code.</p>
        <p>2. Enter the 6-digit code from the email received.</p>
        <el-input v-model="mfaCode" placeholder="Enter 6-digit code" />
        <el-button type="primary" class="mt-2" @click="setupEmailMfaSubmit()">Verify</el-button>
    </el-dialog>

    <el-dialog
      title="Setup TOTP MFA"
      :visible.sync="isShowTotpSetupDialog"
      width="50%">
      <el-row>
        <el-col :span="12" class="text-center">
          <p>1. Scan the QR code below with your authenticator app.</p>
          <img :src="totpQrcode" alt="TOTP QR Code" />
          <div>Or enter secret manually: <br/>{{ totpSecret }}</div>
        </el-col>
        <el-col :span="12">
          <p>2. Enter the 6-digit code generated from your authenticator app.</p>
          <el-input v-model="mfaCode" placeholder="Enter 6-digit code" />
          <el-button type="primary" class="mt-2" @click="setupTotpMfaSubmit()">Verify</el-button>
        </el-col>
      </el-row>
    </el-dialog>
	</el-form>
</template>

<script>
import { BModal } from "bootstrap-vue";
import moment from "moment/moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Prompt from "@/views/erp/mixins/Prompt";
import axios from "axios";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    BModal, moment,
    ToastificationContent
  },
  mixins: [Prompt],
  data() {
    return {
      mfaMethod: null,

      totpSecret: null,
      totpQrcode: null,

      mfaCode: null,
      currentPassword: null,
      newPassword: null,
      newPasswordConfirm: null,

      isShowTotpSetupDialog: false,
      isShowEmailSetupDialog: false,
      isShowRemoveMfaDialog: false,
      isLoading: false,
    }
  },
  methods: {
    changePassword() {
      axios.post('/accounts/change-password', {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        newPasswordConfirm: this.newPasswordConfirm
      }).then(response => {
        if (response.data.success) {
          this.promptInfo('Password changed');
        } else {
          this.promptError('Password change failed: ' + response.data.data);
        }
      }).catch(error => {
        this.promptError('Password change failed: ' + error);
      }).finally(() => {
        this.currentPassword = null;
        this.newPassword = null;
        this.newPasswordConfirm = null;
      });
    },
    loadMfa() {
      axios.get('/accounts/mfa').then(response => {
        if (response.data.success) {
          this.mfaMethod = response.data.data.method;
        } else {
          this.promptError('MFA load failed: ' + response.data);
        }
      }).catch(error => {
        console.error(error);
      });
    },
    removeMfa() {
      this.isShowRemoveMfaDialog = true;
    },
    removeMfaSubmit() {
      axios.delete('/accounts/mfa', {
        data: {
          password: this.currentPassword,
          code: this.mfaCode
        }
      }).then(response => {
        if (response.data.success) {
          this.promptInfo('MFA removed');
          this.isShowRemoveMfaDialog = false;
          this.loadMfa();
        } else {
          this.promptError('MFA remove failed: ' + response.data.data);
        }
      }).catch(error => {
        this.promptError('MFA setup failed: ' + error);
      }).finally(() => {
        this.currentPassword = null;
        this.mfaCode = null;
      });
    },
    sendMfaEmail() {
      axios.post('/accounts/mfa/send-email').then(response => {
        if (response.data.success) {
          this.promptInfo('MFA email sent');
        } else {
          this.promptError('MFA email failed: ' + response.data.data);
        }
      }).catch(error => {
        this.promptError('MFA email failed: ' + error);
      });
    },
    setupEmailMfa() {
      axios.get('/accounts/mfa/email-setup').then(response => {
        if (response.data.success) {
          this.email = response.data.data.email;
          this.isShowEmailSetupDialog = true;
        } else {
          this.promptError('MFA setup email failed: ' + response.data.data);
        }
      }).catch(error => {
        this.promptError('MFA setup failed: ' + error);
      });
    },
    setupEmailMfaSubmit() {
      axios.post('/accounts/mfa/email-setup', {
        code: this.mfaCode
      }).then(response => {
        if (response.data.success) {
          this.promptInfo('MFA setup successful');
          this.isShowEmailSetupDialog = false;
          this.loadMfa();
        } else {
          this.promptError('MFA setup failed: ' + response.data.data);
        }
      }).catch(error => {
        this.promptError('MFA setup failed: ' + error);
      }).finally(() => {
        this.mfaCode = null;
      });
    },
    setupTotpMfa() {
      axios.get('/accounts/mfa/totp-setup').then(response => {
        if (response.data.success) {
          this.totpSecret = response.data.data.secret;
          this.totpQrcode = response.data.data.qrCode;
          this.isShowTotpSetupDialog = true;
        } else {
          this.promptError('MFA setup failed: ' + response.data.data);
        }
      }).catch(error => {
        this.promptError('MFA setup failed: ' + error);
      });
    },
    setupTotpMfaSubmit() {
      axios.post('/accounts/mfa/totp-setup', {
        code: this.mfaCode,
        secret: this.totpSecret,
      }).then(response => {
        if (response.data.success) {
          this.promptInfo('MFA setup successful');
          this.isShowTotpSetupDialog = false;
          this.loadMfa();
        } else {
          this.promptError('MFA setup failed: ' + response.data.data);
        }
      }).catch(error => {
        this.promptError('MFA setup error: ' + error);
        console.error(error);
      }).finally(() => {
        this.mfaCode = null;
        this.totpSecret = null;
      });
    }
  },
  mounted() {
    this.loadMfa();
  }
}
</script>

<style scoped>
	.title-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
</style>
